.services-container {
  align-items: center;

  flex-direction: column;
  display: flex;
  width: 100%;
}

.services_heading {
  width: 50%;
  text-align: center;
}

.service-card-container {
  margin: 0 1.2rem;
}

/* Styles for .services-card (from the child component) */
.services-card {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 33.33%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  text-align: center;
  transition: all 0.3s ease;
  border-radius: 1rem;
  align-self: normal;
  /* height: 450px !important; */
  margin: 0 auto;
}

.services-card:hover {
  box-shadow: 0px 1px 10px gray;
}

.services-card img {
  object-fit: cover;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.services-card h2 {
  margin-top: 0.7rem;
}

.services-card .tags {
  /* padding-top: 0.3rem;
  padding-bottom: 0.5rem; */
  width: 100%;
}

.services-card .tags span {
  display: inline-block;

  color: #31353b;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  margin-right: 0.3rem;
  margin-bottom: 0.3rem;
  background-color: #d6dce2;

  letter-spacing: 0.7px;
}

@media screen and (min-width: 600px) {
  .services-card h2 {
    font-size: 0.8rem;
  }

  .services-card .tags span {
    font-size: 0.4rem;
    font-weight: 100;
  }

  .services-card img {
    width: 65px;
  }
}
@media screen and (min-width: 700px) {
  .services-card {
    flex: 1;
    width: 100%;
  }
}

@media screen and (min-width: 900px) {
  .services-card {
    flex: 1;
    width: 100%;
  }
}

@media screen and (min-width: 1125px) {
  .services-cards {
    padding: 0;
  }
  .contactinfo {
    display: none;
  }
}
