:root {
  --primary-color: #3d5a80;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
}

body {
  width: 80%;
  background: white;
  background-repeat: no-repeat;
  height: 100vh;
  max-width: 1200px;
  margin: 0 auto;
  font-family: "Nunito", sans-serif;
}

.app-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 100%;
  position: relative;

}
/* 
.social_icons {
  position: fixed;
  left: 4%;
  z-index: 10;
  top: 30%;
} */


