.contact {
  display: flex;
  min-height: 70vh;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
  width: 100%;
}

.contact .content {
  display: flex;
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  align-self: center;
  justify-self: center;
}

.contact .left {
  flex: 1;
  overflow: hidden;
}

.contact .left img {
  height: 100%;
}

.contact .right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

form {
  width: 70%;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

form input {
  width: 300px;
  padding: 0.6rem 1rem;
  font-size: 1rem;
  margin-bottom: 1rem;
}

form textarea {
  min-height: 200px;
  width: 300px;
  padding: 0.6rem 1rem;
  font-size: 1rem;
}

form button {
  width: 150px;
  margin-bottom: 1rem;
}

@keyframes popupAnimation {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  20% {
    transform: scale(0.4);
    opacity: 0;
  }
  40% {
    transform: scale(1);
    opacity: 0;
  }
  60% {
    transform: scale(0.6);
    opacity: 0;
  }
  80% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.notification {
  color: #3d5a81;
  font-weight: bold;
  opacity: 0; /* Initially hidden */
  animation: popupAnimation 0.5s ease forwards; /* Animation duration and easing */
}






@media screen and (max-width: 800px) {
  .contact {
    min-height: 55vh;
  }

  .contact .content {
    flex-direction: column;
  }
  .contact .content {
    margin-top: 0;
  }

  .contact .left {
    display: none;
  }

  form input {
    width: 226px;
  }

  form textarea {
    width: 226px;
  }

  form button {
    width: 5px;
  }
}
