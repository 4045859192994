.services-card {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 33.33%;
  display: flex;
  flex-direction: column;
  text-align: center;
  transition: all 0.3s ease;
  border-radius: 1rem;
  align-self: normal;
  padding: 25px;
}

.services-card h2 {
  font-size: 1rem;
}

.services-card:hover {
  box-shadow: 0px 1px 10px gray;
}

.services-card img {
  object-fit: cover;
}

.services-card .tags {
  padding:  0px;
  width: 100%;
}

.services-card .tags span {
  display: inline-block;
  font-size: 0.7rem;
  color: #31353b;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  margin-right: 0.3rem;
  margin-bottom: 0.3rem;
  background-color: #d6dce2;
  font-weight: 800;
  letter-spacing: 0.7px;
  margin-top: 8px !important;
}

@media screen and (min-width: 700px) {
  /* .services-card h2 {
    font-size: 1.6rem;
  } */

  .services-card .tags span {
    font-size: 0.7rem;
    margin: auto;
    margin-right: 3px;
    font-weight: 700;
  }
} 

@media screen and (max-width: 700px) {
  .services-card {
    width: 55%;
    padding: 0 2px 5px 2px;
  }

  .services-card img {
    width: 20px;
  }

  .services-card h2 {
    font-size: 0.9rem;
  }

  .services-card .tags {
    margin-top: 5px;
  }

  .services-card .tags span {
    margin-top: 1px !important;
  }

}
  


@media screen and (min-width: 900px) {
  .services-card {
    flex: 1;
  }
}
