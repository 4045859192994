.heading-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heading {
  margin-top: 3rem;
  color: black;
  font-weight: 500;
  font-size: 1.7rem;
  text-align: center;
}

.heading-container span {
  display: inline-block;
  margin: 0 auto;
  height: 3px;
  background: var(--primary-color);
  width: 20px;
  border-radius: 20px;
}

@media screen and (min-width: 600px) {
  .heading {
    font-size: 5rem;
  }

  .heading-container span {
    height: 5px;
    width: 35px;
  }
}
