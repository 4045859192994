
.home-container {

  width: 100%;
  height: 100%;
  min-height: 90vh;
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.home-container .svg {
  display: none;
}

.home-container .content {
  width: 100%;
  padding: 0 1rem;
  margin-bottom: 2rem;
}

.content h1 {
  font-size: 3rem;
  font-weight: bold;
}

.content p {
  display: block;
  font-size: 1.5rem;
  margin: 1rem 0;
  padding: 0 1rem;
  color: rgb(97, 89, 89);
  align-items: center;
}

.content .home-btn {
  font-size: large;
  display: inline-block;
  margin-bottom: 1rem;
  background-color: var(--primary-color);
  color: white;
  padding: 1rem 2rem;
  border-radius: 1px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin-top: 1rem;
  font-weight: bold;
  border: 1px solid transparent;
}

.home-btn:hover {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  background-color: transparent;
}

/* Medium */
@media screen and (min-width: 481px) {}

/* Large */
@media screen and (min-width: 900px) {
  .home-container {
    flex-direction: row;
    align-items: center;
    text-align: left;
    justify-content: center;
    padding: 0 2rem;
  }

  .home-container .svg {
    display: block !important;
    width: 90%;
    height: 90%;
  }

  .svg img {
    width: 100%;
    height: 100%;
    color: var(--primary-color);
  }

  .home-container .content {
    padding: 0 1rem;
    text-align: left;
  }

  .content h1 {
    font-size: 4rem;
  }

  .content p {
    padding: 0;
    width: 30rem;
    display: flex;
    align-items: center;
  }
  .contactinfo {
    display: none;
  }
  .social_icons {
    position: fixed;
    left: 4%;
    z-index: 10;
    top: 30%;
  
  
}
.contact_icons {
  display: flex;
  position: fixed;
  left: 4%;
  z-index: 10;
  top: 30%;
  flex-direction: column;
  gap: 5px;
}
}



/* Extra Large */
@media screen and (min-width: 1025) {}

/* XXL */
@media screen and (min-width: 1201) {}