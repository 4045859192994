.footer-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  padding: 30px;
  color: gray;
  border-top: 0.5px solid gray;
}
.social-section {
  display: flex;
  gap: 5px;
}

.mb_show {
  display: none;
}
.mb_none {
  display: block;
}
.icons {
  color: var(--primary-color) !important;
  width: 30px;
  height: 30px;
  padding: 4px;
}

@media screen and (max-width: 600px) {
}

/* Large */
@media screen and (max-width: 600px) {
  .footer-container {
    margin-top: 20px;
    flex-direction: column;
    align-items: center;
    text-align: left;
    justify-content: center;
    padding: 0 2rem;
  }

  .social-section {
    margin-top: 20px;
  }

  .copywrite-section p {
    font-size: 10px;
    margin-bottom: 5px;
  }
}
